import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { DeleteButton, EditButton, List, SaveButton, useModal, useSelect, useTable, ShowButton } from "@refinedev/antd";
import { useMany } from "@refinedev/core";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import React from 'react';

const{ Title, Text } = Typography;


const to2 = n => n < 10 ? `0${ n }` : n;

function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}


export const UploadsList = () => {

	const [ isModalOpen, setIsModalOpen ] = React.useState(false);
	const [ currentLog, setCurrentLog ] = React.useState(0);

	const { tableProps } = useTable({
		resource: 'uploadsLog'
	});


	const { data: logDetail, isLoading, isError } = useMany({
        filters: [
            {
                field: 'id',
                operator: 'eq',
                value: currentLog
            }    
        ],
        ids: [currentLog],
        resource: `uploadsLog`,
    });


	const { show, modalProps } = useModal();

	let logDetails = logDetail?.data ?? [];


	const displayLog = (recId) => {

		setCurrentLog(recId);

		setIsModalOpen(true);

	}


	const hideLog = () => {

		setCurrentLog(0);
		setIsModalOpen(false);

	}


	const detailColumns = [
		{
			dataIndex: 'row',
			key: 'id',
			title: 'Row'
		},
		{
			dataIndex: 'message',
			key: 'id',
			title: 'Error description'
		}
	]


	return (

		<>

			<List>
                
				<Table { ...tableProps } 
                    rowKey="id">

					<Table.Column dataIndex="uploaded_file" title="File name" />
					<Table.Column dataIndex="createdAt" title="Uploaded date" render={ (record) => {
						
						let date = new Date(record);

						return `${ to2(date.getMonth() + 1) }/${ to2(date.getDate()) }/${ date.getFullYear() } ${ to2(date.getHours()) }:${ to2(date.getMinutes()) }`

					} } />

					<Table.Column dataIndex="records_count" title="Records count" render={ n => numberFormat(n, 0) } />
					<Table.Column dataIndex="stored_rec_count" title="Stored records" render={ n => numberFormat(n, 0) } />
                    

                    <Table.Column
                        title="Actions"
                        dataIndex="actions"

                        render={ (_text, record) => (

                            <Space>

                            	<ShowButton icon={ <EyeOutlined /> } onClick={ () => displayLog(record.id) } />
                                <DeleteButton 
                                    hideText
                                    recordItemId={ record.id }
                                    resource="uploadsLog"
                                />

                            </Space>

                        )}
                    />
                   
				</Table>

				<Modal {...modalProps}
					cancelButtonProps={{ style: { display: 'none' } }}
	                okText="Close"
	                okType="success"
	                onCancel={ hideLog }
	                onOk={ hideLog }
	                open={ isModalOpen }
	                title={ isLoading ? `Loading data...` : `Upload detail` }
	                width={ 800 }
                >
					
					<Table
						dataSource={ logDetails }
						columns={ detailColumns }
					/>
					
				</Modal>

			</List>

		</>

	);

}