import { SearchOutlined } from '@ant-design/icons';
import { DeleteButton, EditButton, List, SaveButton, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Table, Typography } from "antd";
import React, { useState } from 'react';

const{ Title, Text } = Typography;

export const ZipsAdminList = () => {

    const [ currentState, setCurrentState ] = useState(0);
    const [ currentCounty, setCurrentCounty ] = useState(0);
    const [ currentCity, setCurrentCity ] = useState(0);

	const { searchFormProps, tableProps } = useTable({
        onSearch: (values) => {
            return [
                {
                    field: "state",
                    operator: "equals",
                    value: values.state,
                },
                {
                    field: "ziporcounty",
                    operator: "contains",
                    value: values.ziporcounty,
                },
            ];
        },
		resource: 'zips'
	});


    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm({
        action: "create",
        resource: "zips"
    });


    const { selectProps } = useSelect({
        optionLabel: "state",
        optionValue: "id",
        resource: "states"
    });

    if(selectProps?.options)
        selectProps.options.unshift({ label: 'All', value: 0 });


   

    const { selectProps: stateSelectProps } = useSelect({
        optionLabel: "state",
        optionValue: "id",
        resource: "states",
    });


    const { selectProps: countySelectProps } = useSelect({
        filters: [
            {
                field: 'state_id',
                operator: 'eq',
                value: currentState
            }    
        ],
        optionLabel: "county",
        optionValue: "id",
        queryOptions: {
            enabled: currentState !== '0'
        },
        resource: `counties`,
    });


    const { selectProps: citySelectProps } = useSelect({
        filters: [
            {
                field: 'county_id',
                operator: 'eq',
                value: currentCounty
            }    
        ],
        optionLabel: "city",
        optionValue: "id",
        queryOptions: {
            enabled: currentCounty !== '0'
        },
        resource: `cities`,
    });


	return (

		<>

			<List 
                createButtonProps={{
                    onClick: () => {
                        createModalShow();
                    }
                }}
                title="Zip codes management"
            >
                <Form {...searchFormProps} layout="inline">

                    <Form.Item name="state">
                         <Select
                            placeholder="State"
                            style={{ width: 200 }}
                            { ...selectProps }
                        />
                    </Form.Item>

                    <Form.Item name="ziporcounty">
                        <Input placeholder="Enter zip or county" allowClear onChange={ e => { if(e.target.value === '') searchFormProps.form?.submit()  } } />
                    </Form.Item>

                   

                    <Button icon={<SearchOutlined />} onClick={searchFormProps.form?.submit}>Search</Button>
                    <br/>&nbsp;<br/>
                </Form>
				<Table { ...tableProps } rowKey="id">

                    <Table.Column dataIndex="zip_code" title="Zip code" />

                    <Table.Column dataIndex="cities_c.city" title="City" render={ (t, r) => r.cities_c ? r.cities_c.city : '' } />

                    <Table.Column dataIndex="cities_c.city" title="County" render={ (t, r) => r.cities_c?.counties_c ? r.cities_c.counties_c.county : '' } />

                    <Table.Column dataIndex="cities_c.city" title="State" render={ (t, r) => r.cities_c?.counties_c?.states_c ? r.cities_c.counties_c.states_c.state : '' } />
                    
                    
                    <Table.Column
                        title="Actions"
                        dataIndex="actions"

                        render={ (_text, record) => (

                            <Space>

                                <EditButton
                                    size="small"
                                    recordItemId={ record.id }
                                    hideText
                                />

                                <DeleteButton 
                                    hideText
                                    recordItemId={ record.id }
                                />

                            </Space>

                        )}
                    />
                   
				</Table>

			</List>


            <Modal { ...createModalProps }>

                <Form { ...createFormProps } layout="vertical">

                    <Form.Item
                        label="State"
                        name="state_id"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select { ...stateSelectProps } onChange={ setCurrentState } />
                    </Form.Item>

                    <Form.Item
                        label="County"
                        name="county_id"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select { ...countySelectProps } onChange={ setCurrentCounty } />
                    </Form.Item>

                    <Form.Item 
                        label="City"
                        name="city_id"
                        rules={ [ { required: true } ] }
                    >
                        <Select { ...citySelectProps } />
                    </Form.Item>

                    <Form.Item 
                        label="Zip code"
                        name="zip_code"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>

		</>

	);

}