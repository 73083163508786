import React from 'react';
import { Button, Col, Row, Typography } from "antd";

const{ Link, Title, Text } = Typography;

export const DealershipMetadata = (props) => {

	const [ currentlyDisplayingPage, setCurrentlyDisplayingPage ] = React.useState(1);

	const { dealershipMetadata } = props;

	if(!dealershipMetadata){

		return null;

	}


	const pageMetadata = currentlyDisplayingPage === 1 ? dealershipMetadata.dealerships_metadata.slice(0, 14) : dealershipMetadata.dealerships_metadata.slice(14);


	return (

		<>

			<Row gutter={ [8, 40] }>

				{

					pageMetadata.map(meta => 

						<Col span={ 8 }>
							<Title level={ 5 }>{ meta.dealership_meta_c.label }</Title>
							{
								meta.dealership_meta_c.meta_key === 'Website' || meta.dealership_meta_c.meta_key === 'Payment_Link' || meta.dealership_meta_c.meta_key === 'Saleforce_Customer_Link' || meta.dealership_meta_c.meta_key === 'FAQs' ?
								<Link href={ meta.meta_value } target="_blank" style={{ color: '#777', textDecoration: 'underline' }} >{ meta.meta_value }</Link>
								:
								<Text>{ meta.meta_value }<br/>&nbsp;<br/></Text>
								
							}
		                    
						</Col>

					)

				}

			</Row>

			<br/>&nbsp;<br/>

			<Row justify="end" gutter={ [8, 40] }>

				<Col span={ 4 } >

					<Button onClick={ e => currentlyDisplayingPage === 1 ? setCurrentlyDisplayingPage(2) : setCurrentlyDisplayingPage(1) }>{ currentlyDisplayingPage === 1 ? 'Next' : 'Previous' } page </Button>

				</Col>

			</Row>

		</>

	);


}