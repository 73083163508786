import { SearchOutlined } from '@ant-design/icons';
import { List, useSelect, useTable } from "@refinedev/antd";
import { useGo } from "@refinedev/core";
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Table, Typography } from "antd";
import React from 'react';

import { DealershipMetadata } from './dealershipMetadata';
import { ExtensionsList } from './extensionsList';
import { ATDCommissionAgents } from './atdCommissionAgents';
import { SOW } from './sow';
import { Surfaces } from './surfaces';

const{ Title, Text } = Typography;

export const ZipsList = () => {

    const _existingStoredUser = localStorage.getItem('userIdentity');
    let existingStoredUser;

    if(_existingStoredUser){
        existingStoredUser = JSON.parse(_existingStoredUser);
    }


    const [ currentlyDisplayingDealership, setCurrentlyDisplayingDealership ] = React.useState(null);
    const [ isDealershipModalOpen, setIsDealershipModalOpen ] = React.useState(false);
    const [ isSupportInfoDisplaying, setIsSupportInfoDisplaying ] = React.useState(false);
    const [ isExtensionsListDisplaying, setIsExtensionsListDisplaying ] = React.useState(false);
    const [ isATDInfoDisplaying, setIsATDInfoDisplaying ] = React.useState(false);
    const [ isSOWInfoDisplaying, setIsSOWInfoDisplaying ] = React.useState(false);
    const [ isSurfacesInfoDisplaying, setIsSurfacesInfoDisplaying ] = React.useState(undefined);

    const go = useGo();

    const locale = {
        emptyText: 'NO DEALER IN AREA - COMMERCIAL PROJECT LEADS TO SNA (email to ddann@sundek.com)'
    }


	const { searchFormProps, tableProps } = useTable({
        onSearch: (values) => {
            return [
                {
                    field: "state",
                    operator: "equals",
                    value: values.state,
                },
                {
                    field: "ziporcounty",
                    operator: "contains",
                    value: values.ziporcounty,
                },
            ];
        },
		resource: 'zips'
	});


    const { selectProps } = useSelect({
        optionLabel: "State__c",
        optionValue: "State__c",
        resource: "states",
        
    });

    
    if(selectProps?.options)
        selectProps.options.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);

    if(!selectProps?.options.find(e => e.label === 'All')){

        selectProps.options.unshift({ label: 'All', value: 0 })

    }


    const displayDealershipInformation = (dealershipIndex) => {

        setCurrentlyDisplayingDealership(tableProps.dataSource[parseInt(dealershipIndex)]);
        setIsDealershipModalOpen(true);

    }


    const hideDealershipInformation = () => {

        setCurrentlyDisplayingDealership(null);
        setIsDealershipModalOpen(false);

    }


	return (

		<>


			<List 
                title="Sundek Nation Territories"
                headerButtons={({ defaultButtons }) => (
                    <>
                        { defaultButtons }

                        {
                            existingStoredUser.role === 'administrator' &&
                            <>
                                <Button type="primary" onClick={ () => {
                                    go({
                                        to: '/bulkUpdate',
                                        type: 'push'
                                    })
                                } }>Bulk update</Button>

                                <Button type="primary" onClick={ () => {
                                    go({
                                        to: '/uploadsLog',
                                        type: 'push'
                                    })
                                } }>Uploads log</Button>
                            </>
                        }

                        
                    </>
                )}
            >
                <Form {...searchFormProps} layout="inline">

                    <Form.Item name="ziporcounty">
                        <Input placeholder="Enter zip or county" allowClear onChange={ e => { if(e.target.value === '') searchFormProps.form?.submit()  } } onPressEnter={ e => searchFormProps.form?.submit() } />
                    </Form.Item>

                    <Form.Item name="state">
                         <Select
                            placeholder="State"
                            
                            style={{ width: 200 }}
                            { ...selectProps }
                            filterOption={ true }
                            optionFilterProp="label"
                        />
                    </Form.Item>

                    
                    <Button icon={<SearchOutlined />} onClick={ searchFormProps.form?.submit }>Search</Button>
                    <br/>&nbsp;<br/>
                </Form>
				<Table { ...tableProps } 
                    locale={ locale }
                    onRow={ (rowKey, rowIndex) => {
                        return {
                            onClick: e => displayDealershipInformation(rowIndex)
                        }
                    }}
                    rowKey="Name">

					
                    <Table.Column dataIndex="Name" title="Zip code" />

                    <Table.Column dataIndex="City__c" title="City" />

                    <Table.Column dataIndex="State__c" title="State" />
                    
                    
                    <Table.Column dataIndex="Dealer__r" title="Dealership" render={ (t, r) => r.Dealer__r ? r.Dealer__r.Name : 'NO DEALER IN AREA' } />
                    <Table.Column dataIndex="SalesRep__r" title="Sales rep" render={ (t, r) => r.SalesRep__r ? r.SalesRep__r.Name : '' } />
                    <Table.Column dataIndex="Dealer__r" title="Gm / Owner" render={ (t, r) => r.Dealer__r ? r.Dealer__r.GM_Owner__c : '' } />
                    <Table.Column dataIndex="Area__c" title="Code" />
                    <Table.Column dataIndex="SalesRepText__c" title="Sales rep. text" />
                    <Table.Column dataIndex="CodeVarious__c" title="Various" />
                    <Table.Column dataIndex="SpecialInstructions__c" title="Special instructions" />

                   
				</Table>

			</List>

			<Modal 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                okType="success"
                onCancel={ hideDealershipInformation }
                onOk={ hideDealershipInformation }
                open={ isDealershipModalOpen }
                title={ currentlyDisplayingDealership?.Dealer__r ? <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}> { currentlyDisplayingDealership.Dealer__r.Name }</p> : 'NO DEALER IN AREA' }
                width={ 900 }
            >

                {
                    currentlyDisplayingDealership &&
                    <>
                        <Row gutter={[12, 23]}>
                            
                            {
                                currentlyDisplayingDealership.Dealer__r?.GM_Owner__c  &&
                                <Col span={ 12 }>
                                    <Title level={ 5 }>GM / Owner</Title>
                                    <Text>{ currentlyDisplayingDealership.Dealer__r.GM_Owner__c }</Text>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.Dealer__r?.Contact_Email__c &&
                                <Col span={ 12 }>
                                    <Title level={5}>Contact email</Title>
                                    <Text>{ currentlyDisplayingDealership.Dealer__r.Contact_Email__c }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.Dealer__r?.Type &&
                                <Col span={ 12 }>
                                    <Title level={5}>Dealer type</Title>
                                    <Text>{ currentlyDisplayingDealership.Dealer__r.Type }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.Dealer__r?.Phone &&
                                <Col span={ 12 }>
                                    <Title level={5}>Phone no.</Title>
                                    <Text>{ currentlyDisplayingDealership.Dealer__r.Phone }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                        </Row>


                        {
                            currentlyDisplayingDealership.Dealer__r && existingStoredUser.role !== 'general' &&
                            <>
                                <br/>
                                <Title level={ 3 }>Scheduling</Title>
                                <Divider orientation="left" />
                                <Row gutter={ [ 32, 16 ] } >
                                    {
                                        currentlyDisplayingDealership.SalesRep__r?.Name &&
                                        <Col span={ 12 }>
                                            <Title level={5}>Sales rep</Title>
                                            <Text>{ currentlyDisplayingDealership.SalesRep__r?.Name }</Text><br/>&nbsp;<br/>
                                        </Col>
                                    }
                                    {
                                        currentlyDisplayingDealership.Area__c &&
                                         <Col span={ 12 }>
                                            <Title level={5}>Code</Title>
                                            <Text>{ currentlyDisplayingDealership.Area__c }</Text><br/>&nbsp;<br/>
                                        </Col>
                                    }
                                    {
                                        currentlyDisplayingDealership.SalesRepText__c &&
                                        <Col span={ 12 }>
                                            <Title level={5}>Sales rep - text</Title>
                                            <Text>{ currentlyDisplayingDealership.SalesRepText__c }</Text><br/>&nbsp;<br/>
                                        </Col>
                                    }
                                    
                                    <Col span={ 12 }>
                                        <Title level={5}>Various</Title>
                                        <Text>{ currentlyDisplayingDealership.CodeVarious__c }</Text><br/>&nbsp;<br/>
                                    </Col>
                                    <Col span={ 12 }>
                                        <Title level={5}>Special instructions</Title>
                                        <Text>{ currentlyDisplayingDealership.SpecialInstructions__c }</Text><br/>&nbsp;<br/>
                                    </Col>
                                    {
                                        currentlyDisplayingDealership.Dealer__r?.IT_System__c &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>IT System: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.IT_System__c }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }
                                </Row>

                                <Row>
                                    <Col span={ 4 } offset={ 17 } >
                                        <Button onClick={ e => setIsSupportInfoDisplaying(true) }>Support info</Button>
                                    </Col>
                                </Row>

                                 <Row>
                                    <Col span={ 4 } offset={ 17 } >
                                        <Button onClick={ e => setIsExtensionsListDisplaying(true) }>Extensions list</Button>
                                    </Col>
                                </Row>

                                {
                                    currentlyDisplayingDealership.Dealer__r?.Name === 'ATD Concrete Coatings' &&
                                    <Row>
                                        <Col span={ 4 } offset={ 17 } >
                                            <Button onClick={ e => setIsATDInfoDisplaying(true) }>Commission agents</Button>
                                        </Col>
                                    </Row>
                                }

                                {
                                    currentlyDisplayingDealership?.Dealer__r.Name?.toUpperCase() === 'SUNDEK OF WASHINGTON' &&
                                    <Row>
                                        <Col span={ 4 } offset={ 17 } >
                                            <Button onClick={ e => setIsSOWInfoDisplaying(true) }>Identifying and Assigning projects</Button>
                                        </Col>
                                    </Row>
                                }
                                {
                                    ['ATD CONCRETE COATINGS', 'SUNDEK OF AUSTIN', 'SUNDEK OF HOUSTON', 'SUNDEK OF NASHVILLE', 'SUNDEK OF SAN ANTONIO',  'SUNDEK OF WASHINGTON'].includes(currentlyDisplayingDealership?.Dealer__r.Name?.toUpperCase()) &&
                                    <Row>
                                        <Col span={ 4 } offset={ 17 } >
                                            <Button onClick={ e => setIsSurfacesInfoDisplaying(currentlyDisplayingDealership?.Dealer__r.Name?.toUpperCase()) }>Surfaces</Button>
                                        </Col>
                                    </Row>
                                }
                                

                                
                            </>
                        }

                        {

                            
                            currentlyDisplayingDealership !== null &&
                            <>
                                <br/>
                                <Title level={ 3 }>Aditional details</Title>

                                <Divider orientation="left" />
                                <Row>

                                    { 
                                        currentlyDisplayingDealership.Dealer__r?.Legal_Name__c &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Legal name: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.Legal_Name__c }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }


                                    {   
                                        currentlyDisplayingDealership.Dealer__r?.Industry &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Industry: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.Industry }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }
                                    

                                    {
                                        currentlyDisplayingDealership.Dealer__r?.Contractor_Type__c &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Contractor type: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.Contractor_Type__c }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }

                                    
                                    {
                                        currentlyDisplayingDealership.Dealer__r?.Sundek_Dealer_Since__c &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Sundek dealer since: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.Sundek_Dealer_Since__c }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }

                                    {
                                        currentlyDisplayingDealership.Dealer__r?.YearsAsSundekDealer__c &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Years as Sundek dealer: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.YearsAsSundekDealer__c }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }
                                    
                                    {
                                        currentlyDisplayingDealership.Dealer__r?.Area__c &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Area: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.Area__c }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }
                                    
                                    
                                    {
                                        currentlyDisplayingDealership.Dealer__r?.BillingAddress &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Billing address: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.BillingAddress?.street ? currentlyDisplayingDealership.Dealer__r?.BillingAddress.street : '' }, { currentlyDisplayingDealership.Dealer__r?.BillingAddress?.city ? currentlyDisplayingDealership.Dealer__r?.BillingAddress.city : '' }, { currentlyDisplayingDealership.Dealer__r?.BillingAddress?.state ? currentlyDisplayingDealership.Dealer__r?.BillingAddress.state : '' }, { currentlyDisplayingDealership.Dealer__r?.BillingAddress?.postalCode ? currentlyDisplayingDealership.Dealer__r?.BillingAddress.postalCode : '' }<br/>&nbsp;<br/> </Text>
                                        </Col>
                                    }
                                    
                                     
                                    {
                                        currentlyDisplayingDealership.Dealer__r?.SicDesc &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Dealer license type: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.SicDesc }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }

                                    
                                    {
                                        currentlyDisplayingDealership.Dealer__r?.Sic &&
                                        <Col span={ 12 }>
                                            <Title level={ 5 }>Dealer license number: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.Sic }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }
                                    
                                    {
                                        currentlyDisplayingDealership.Dealer__r?.Territory__c &&
                                        <Col span={ 24 }>
                                            <Title level={ 5 }>Territory: </Title><Text>{ currentlyDisplayingDealership.Dealer__r?.Territory__c }<br/>&nbsp;<br/></Text>
                                        </Col>
                                    }

                                </Row>
                            </>

                            
                        }


                    </>
                }

            </Modal>

            <Modal 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                okType="success"
                onCancel={ e => setIsSupportInfoDisplaying(false) }
                onOk={ e => setIsSupportInfoDisplaying(false) }
                open={ isSupportInfoDisplaying }
                title={ <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}>Dealership support info </p> }
                width="90%"
            >

                {
                    
                    currentlyDisplayingDealership !== null &&
                    <DealershipMetadata dealershipMetadata={ currentlyDisplayingDealership.dealershipMetaData } /> 
                }
                
                

            </Modal>

            <Modal 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                okType="success"
                onCancel={ e => setIsExtensionsListDisplaying(false) }
                onOk={ e => setIsExtensionsListDisplaying(false) }
                open={ isExtensionsListDisplaying }
                title={ <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}>Extensions list </p> }
                width="90%"
            >

                {
                    
                    currentlyDisplayingDealership !== null &&
                    <ExtensionsList /> 
                }
                
            </Modal>

            
            <Modal 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                okType="success"
                onCancel={ e => setIsATDInfoDisplaying(false) }
                onOk={ e => setIsATDInfoDisplaying(false) }
                open={ isATDInfoDisplaying }
                title={ <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}>ATD Commission Agent List by Rep</p> }
                width="90%"
            >

                {
                    
                    currentlyDisplayingDealership !== null &&
                    <ATDCommissionAgents /> 
                }
                
            </Modal>

            <Modal 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                okType="success"
                onCancel={ e => setIsSOWInfoDisplaying(false) }
                onOk={ e => setIsSOWInfoDisplaying(false) }
                open={ isSOWInfoDisplaying }
                title={ <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}>SOW - Properly identifying and assigning SOW Commercial Projects</p> }
                width="50%"
            >

                {
                    
                    currentlyDisplayingDealership !== null &&
                    <SOW /> 
                }
                
            </Modal>


            <Modal 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                okType="success"
                onCancel={ e => setIsSurfacesInfoDisplaying(undefined) }
                onOk={ e => setIsSurfacesInfoDisplaying(undefined) }
                open={ isSurfacesInfoDisplaying }
                title={ <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}>Surfaces</p> }
                width="89%"
            >

                {
                    
                    currentlyDisplayingDealership !== null &&
                    <Surfaces dealership={ isSurfacesInfoDisplaying }/> 
                }
                
            </Modal>


		</>

	);

}