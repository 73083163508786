import { List, useSelect, useTable } from "@refinedev/antd";
import { Form, Input, Select, Table } from "antd";
import React from 'react';

import surfacesList from './surfaces.json';

export const Surfaces = ({dealership}) => {


	const dealerKeys = {
		'ATD CONCRETE COATINGS': 'ATD',
		'SUNDEK OF AUSTIN': 'SOA',
		'SUNDEK OF HOUSTON': 'SOH',
		'SUNDEK OF NASHVILLE': 'SON',
		'SUNDEK OF SAN ANTONIO': 'SOSA',
		'SUNDEK OF WASHINGTON': 'SOW'
	}


	const tCols = [
		{
			dataIndex: 'Surface Type',
			key: 'Surface Type',
			title: 'Surface Type'
		},
		{
			dataIndex: 'Definition',
			key: 'Definition',
			title: 'Definition'
		},
		{
			dataIndex: 'Image',
			key: 'Image',
			title: 'Image',
			render: (_, { Image }) => (<> <img src={`/images/${ Image }.jpg`} style={{ maxWidth: '100%' }} />  </>) 
		},
		{
			dataIndex: 'Consider',
			key: 'Consider',
			title: 'Consider'
		},
		{
			dataIndex: dealerKeys[dealership],
			key: 'Schedule',
			title: 'Schedule'
		}
	];



	return (

		<>

			<Table dataSource={ surfacesList } columns={ tCols } rowKey="Surface Type" />

		</>

	);

}