
import { Typography } from "antd";
import React from 'react';

const{ Title, Text } = Typography;


export const SOW = () => {

	return (

		<>

		<Title level={ 5 }>1.  Always ask if the project is Commercial or Residential.</Title>
		<Text>If Commercial follow #2</Text><br/>
		<Text>If Residential assign per Zip Code Lookup</Text>

		<Title level={ 5 }>2.  Are you associated with or working with an Arquitect, Builder or Property Mgmt Company?</Title>
		<Text>If YES follow #3</Text><br/>
		<Text>If NO follow #4</Text>

		<Title level={ 5 }>3.  ID the Company & Check List of Robert Miller's Commission Agents</Title>
		<Text>If ON the list schedue with Robert Miller</Text><br/>
		<Text>If NOT on the list follow #4</Text>

		<Title level={ 5 }>4.  Search for the Company in Salesforce</Title>
		<Text>If IN Salesforce schedule with Customer Account Owner</Text><br/>
		<Text>If NOT in Salesforce follow #5</Text>

		<Title level={ 5 }>5.  Do you currently work directly with one of our Design Consultants</Title>
		<Text>If YES schedule with Consultant</Text><br/>
		<Text>If NO schedule per Zip Code Lookup</Text>

		<Title level={ 5 }>6.  If unsure gather ALL information create Lead or update existing Records</Title>
		<Text>Assign to Monica</Text><br/>

		</>

	);

}