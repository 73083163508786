import { Refine } from '@refinedev/core';
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { ErrorComponent, notificationProvider, useNotificationProvider, ThemedLayoutV2, ThemedTitleV2} from '@refinedev/antd';
import "@refinedev/antd/dist/reset.css";

import { App as AntdApp } from "antd"
import { BrowserRouter, Route, Routes, Outlet, useParams } from "react-router-dom";
import routerBindings, { NavigateToResource, CatchAllNavigate, UnsavedChangesNotifier, DocumentTitleHandler } from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";

import { accessControlProvider, verifyUser } from '../../auth';
import { API_URL } from '../../config/settings';
import { ColorModeContextProvider } from "../../contexts/color-mode";

import { AppIcon } from "../app-icon";
import { Header } from "../header";

import { CitiesEdit, CitiesList } from '../cities';
import { CountiesList } from '../counties';
import { DealershipsList } from '../dealerships';
import { DealershipsMetaCList } from '../dealershipsMetaC';
import { SalesRepsList } from '../salesReps';
import { StatesList } from '../states';
import { Updater } from '../updater';
import { UploadsList } from '../uploadsLog';
import { ZipsList } from '../zips';
import { ZipCodesEdit, ZipsAdminList } from '../zipsAdmin';
import { ZipsDealershipsList } from '../zipsDealerships';


function App() {
    

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uid = urlParams.get('uid');


    if(uid){
        verifyUser(uid);
    }

    
    return (
        <BrowserRouter>
        
        <RefineKbarProvider>
            <ColorModeContextProvider>
            <AntdApp>
            <DevtoolsProvider>
                <Refine
                    accessControlProvider={ accessControlProvider }
                    dataProvider={ dataProvider(API_URL) }
                    notificationProvider={ notificationProvider }
                    routerProvider={routerBindings}
                    resources={[
                        
                        {
                            
                            name: "zips",
                            list: "/zips",
                            show: "/zips/show/:id",
                            meta: {
                                canDelete: false,
                            },
                        }
                    ]}
                    options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: true,
                    }}
                    >
                        <Routes>
                            <Route
                                element={
                                    <ThemedLayoutV2
                                        Header={Header}
                                        Title={({ collapsed }) => (
                                            <ThemedTitleV2
                                                collapsed={collapsed}
                                                text="Sundek Nation Territories"
                                                icon={<AppIcon />}
                                            />
                                        )}
                                    >
                                    <Outlet />
                                    </ThemedLayoutV2>
                                }
                            >

                               
                                <Route
                                    index
                                    element={<NavigateToResource resource="zips" />}
                                />


                                <Route path="/bulkUpdate">
                                    <Route index element={ <Updater /> } />
                                </Route>

                                <Route path="/uploadsLog">
                                    <Route index element={ <UploadsList /> } />
                                </Route>


                                <Route path="/zips">
                                    <Route index element={<ZipsList />} />
                                </Route>

                            </Route>

                        </Routes>

                        <RefineKbar />
                        <UnsavedChangesNotifier />
                    </Refine>
                <DevtoolsPanel />
            </DevtoolsProvider>
            </AntdApp>
            </ColorModeContextProvider>
        </RefineKbarProvider>
        </BrowserRouter>
      );
};

export default App;
