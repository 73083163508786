import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { DeleteButton, EditButton, List, SaveButton, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import React from 'react';

const{ Title, Text } = Typography;

export const StatesList = () => {

	const { searchFormProps, tableProps } = useTable({
        onSearch: (values) => {
            return [
                {
                    field: "state",
                    operator: "contains",
                    value: values.state,
                },
            ];
        },
		resource: 'states'
	});


	const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm({
        action: "create",
        resource: "states"
    });


    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm({
        action: "edit",
        resource: "states",
        warnWhenUnsavedChanges: true,
    });


    return (

		<>

			<List 
                createButtonProps={{
                    onClick: () => {
                        createModalShow();
                    }
                }}
                title="States management"
            >

                <Form {...searchFormProps} layout="inline">

                    <Form.Item name="state">
                        <Input placeholder="State" allowClear onChange={ e => { if(e.target.value === '') searchFormProps.form?.submit()  } } />
                    </Form.Item>

                   

                    <Button icon={<SearchOutlined />} onClick={searchFormProps.form?.submit}>Search</Button>
                    <br/>&nbsp;<br/>
                </Form>
				<Table { ...tableProps } 
                    rowKey="id">

					<Table.Column dataIndex="state" title="State" />
                    

                    <Table.Column
                        title="Actions"
                        dataIndex="actions"

                        render={ (_text, record) => (

                            <Space>

                                <EditButton
                                    size="small"
                                    recordItemId={ record.id }
                                    hideText
                                    onClick={ () => editModalShow(record.id) }
                                />

                                <DeleteButton 
                                    hideText
                                    recordItemId={ record.id }
                                />

                            </Space>

                        )}
                    />
                   
				</Table>

			</List>


			 <Modal { ...editModalProps }>

                <Form { ...editFormProps } layout="vertical">

                    <Form.Item 
                        label="State"
                        name="state"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>


            <Modal { ...createModalProps }>

                <Form { ...createFormProps } layout="vertical">

                    <Form.Item 
                        label="State"
                        name="state"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>

		</>

	);

}