import { List, useSelect, useTable } from "@refinedev/antd";
import { Form, Input, Select, Table } from "antd";
import React from 'react';

export const ExtensionsList = () => {

	const { selectProps } = useSelect({optionLabel: 'dealership',
		optionValue: 'dealership',
		resource: 'zips/dealershipAbreviations',
		sorters: [
			{
				field: 'dealership',
				order: 'asc'
			}
		]
	});



	if(!selectProps?.options.find(e => e.label === 'All')){

        selectProps.options.unshift({ label: 'All', value: 0 })

    }



	const { searchFormProps, tableProps } = useTable({
        
        onSearch: values => {
        	return [
	        	{
	        		field: 'dealership',
	        		operator: 'contains',
	        		value: values.dealership
	        	}
        	]
        },
		resource: 'zips/extensionsList'

	});


	return (

		<List title="" >

			<Form {...searchFormProps} layout="inline">

				<Form.Item name="dealership">
					<Select onChange={ e => { searchFormProps.form?.submit()  } } placeholder="Search by Dealership" style={{ width: 97 }} { ...selectProps } />
				</Form.Item>

			</Form>


			<Table { ...tableProps } rowKey="id">

				<Table.Column dataIndex="dealership" title="Dealer" />

				<Table.Column dataIndex="first_name" title="First name" />

                <Table.Column dataIndex="last_name" title="Last name" />

                <Table.Column dataIndex="email" title="E-mail" />

                <Table.Column dataIndex="department" title="Department" />
                
                <Table.Column dataIndex="ext" title="Ext" />

                <Table.Column dataIndex="did" title="DID" />

                <Table.Column dataIndex="cell_phone" title="Cell phone" />

                <Table.Column dataIndex="responsibilities" title="Responsibilities" />
               
			</Table>

		</List>

	);



}