import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { DeleteButton, EditButton, List, useModalForm, useTable } from "@refinedev/antd";
import { useMany } from "@refinedev/core";
import { Button, Col, Divider, Form, Input, Modal, Row, Space, Switch, Table, Typography } from "antd";
import React from 'react';

const{ Title, Text } = Typography;

export const SalesRepsList = () => {

	const { searchFormProps, tableProps } = useTable({
        onSearch: (values) => {
            return [
                {
                    field: "salesRep",
                    operator: "contains",
                    value: values.salesRep,
                },
            ];
        },
		resource: 'salesReps'
	});


    const [ currentlyDisplayingSalesRep, setCurrentlyDisplayingSalesRep ] = React.useState(null);
    const [ isSalesRepModalOpen, setIsSalesRepModalOpen ] = React.useState(false);


    const displaySalesRepInformation = (record) => {

        setCurrentlyDisplayingSalesRep(record);
        setIsSalesRepModalOpen(true);

    }


    const hideSalesRepInformation = () => {

        setCurrentlyDisplayingSalesRep(null);
        setIsSalesRepModalOpen(false);

    }


	const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm({
        action: "create",
        resource: "salesReps"
    });


    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm({
        action: "edit",
        resource: "salesReps",
        warnWhenUnsavedChanges: true,
    });

    const { data: dealershipsMetasCatalog } = useMany({
        resource: "dealershipsMetaC",
        ids: [ ],
    });


	return (

		<>

			<List 
                createButtonProps={{
                    onClick: () => {
                        createModalShow();
                    }
                }}
                title="Dealerships management"
            >

                <Form {...searchFormProps} layout="inline">

                    <Form.Item name="salesRep">
                        <Input placeholder="Sales rep" allowClear onChange={ e => { if(e.target.value === '') searchFormProps.form?.submit()  } } />
                    </Form.Item>

                   

                    <Button icon={<SearchOutlined />} onClick={searchFormProps.form?.submit}>Search</Button>
                    <br/>&nbsp;<br/>
                </Form>
				<Table { ...tableProps } 
                    rowKey="id">

					<Table.Column dataIndex="salesRep" title="Sales rep" />
                    <Table.Column dataIndex="code" title="Code" />
                    <Table.Column dataIndex="area" title="Area" />
                    <Table.Column dataIndex="specialInstructions" title="Special instructions" />

                    <Table.Column
                        title="Actions"
                        dataIndex="actions"

                        render={ (_text, record) => (

                            <Space>

                                <Button icon={ <EyeOutlined /> } onClick={ (e) => displaySalesRepInformation(record) } />

                                <EditButton
                                    size="small"
                                    recordItemId={ record.id }
                                    hideText
                                    onClick={ () => editModalShow(record.id) }
                                />

                                <DeleteButton 
                                    hideText
                                    recordItemId={ record.id }
                                />

                            </Space>

                        )}
                    />
                   
				</Table>

			</List>

			<Modal 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                okType="success"
                onCancel={ hideSalesRepInformation }
                onOk={ hideSalesRepInformation }
                open={ isSalesRepModalOpen }
                title={ currentlyDisplayingSalesRep ? <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}> { currentlyDisplayingSalesRep.salesRep }</p> : 'NO DATA YET' }
                width={ 700 }
            >

                {
                    currentlyDisplayingSalesRep &&
                    <>
                        <Row gutter={[12, 23]}>
                            
                            {
                                currentlyDisplayingSalesRep.salesRep &&
                                <Col span={ 12 }>
                                    <Title level={ 5 }>Name</Title>
                                    <Text>{ currentlyDisplayingSalesRep.salesRep }</Text>
                                </Col>
                            }

                            {
                                currentlyDisplayingSalesRep.code &&
                                <Col span={ 24 }>
                                    <Title level={5}>Code</Title>
                                    <Text>{ currentlyDisplayingSalesRep.code }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingSalesRep.area &&
                                <Col span={ 24 }>
                                    <Title level={5}>Area</Title>
                                    <Text>{ currentlyDisplayingSalesRep.area }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingSalesRep.specialInstructions &&
                                <Col span={ 24 }>
                                    <Title level={5}>Special instructions</Title>
                                    <Text>{ currentlyDisplayingSalesRep.specialInstructions }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                        </Row>

                    </>
                }

            </Modal>



            <Modal { ...editModalProps }>

                <Form { ...editFormProps } layout="vertical">

                    <Form.Item 
                        label="Sales Rep"
                        name="salesRep"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Code"
                        name="code"
                        
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item 
                        label="Area"
                        name="area"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Special instructions"
                        name="specialInstructions"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>


            <Modal { ...createModalProps }>

                <Form { ...createFormProps } layout="vertical">

                    <Form.Item 
                        label="Sales Rep"
                        name="salesRep"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Code"
                        name="code"
                        
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item 
                        label="Area"
                        name="area"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Special instructions"
                        name="specialInstructions"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>

		</>

	);

}