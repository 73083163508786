import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { DeleteButton, EditButton, List, useModalForm, useTable } from "@refinedev/antd";
import { useMany } from "@refinedev/core";
import { Button, Col, Divider, Form, Input, Modal, Row, Space, Switch, Table, Typography } from "antd";
import React from 'react';

const{ Title, Text } = Typography;

export const DealershipsList = () => {

	const { searchFormProps, tableProps } = useTable({
        onSearch: (values) => {
            return [
                {
                    field: "dealership",
                    operator: "contains",
                    value: values.dealership,
                },
            ];
        },
		resource: 'dealerships'
	});


    const [ currentlyDisplayingDealership, setCurrentlyDisplayingDealership ] = React.useState(null);
    const [ isDealershipModalOpen, setIsDealershipModalOpen ] = React.useState(false);


    const displayDealershipInformation = (record) => {

        setCurrentlyDisplayingDealership(record);
        setIsDealershipModalOpen(true);

    }


    const hideDealershipInformation = () => {

        setCurrentlyDisplayingDealership(null);
        setIsDealershipModalOpen(false);

    }


	const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm({
        action: "create",
        resource: "dealerships"
    });


    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm({
        action: "edit",
        resource: "dealerships",
        warnWhenUnsavedChanges: true,
    });

    const { data: dealershipsMetasCatalog } = useMany({
        resource: "dealershipsMetaC",
        ids: [ ],
    });


    const handleOnEditFinish = (values) => {

        let keys = Object.keys(values);

        let metadataKeys = keys.filter(e => e.indexOf('dealerships_metadata_') === 0);

        let dealerships_metadata = [ ];
        let currentMetadataKey;

        for(let i = 0; i < metadataKeys.length; i++){

            currentMetadataKey = metadataKeys[i];

            if(values[currentMetadataKey]){
                let key_id = metadataKeys[i].substring('dealerships_metadata_'.length);
                dealerships_metadata.push({ meta_key: key_id, meta_value: values[currentMetadataKey] });
            }
            
            editFormProps.onFinish?.({
                ...values,
                dealerships_metadata,
            });
            
        }

    }


	return (

		<>

			<List 
                createButtonProps={{
                    onClick: () => {
                        createModalShow();
                    }
                }}
                title="Dealerships management"
            >

                <Form {...searchFormProps} layout="inline">

                    <Form.Item name="dealership">
                        <Input placeholder="Dealership" allowClear onChange={ e => { if(e.target.value === '') searchFormProps.form?.submit()  } } />
                    </Form.Item>

                   

                    <Button icon={<SearchOutlined />} onClick={searchFormProps.form?.submit}>Search</Button>
                    <br/>&nbsp;<br/>
                </Form>
				<Table { ...tableProps } 
                    rowKey="id">

					<Table.Column dataIndex="dealership" title="Dealership" />
                    <Table.Column dataIndex="gm_owner" title="GM / Owner" />
                    <Table.Column dataIndex="dealer_type" title="Dealer type" />
                    <Table.Column dataIndex="territory" title="Territory" />

                    <Table.Column
                        title="Actions"
                        dataIndex="actions"

                        render={ (_text, record) => (

                            <Space>

                                <Button icon={ <EyeOutlined /> } onClick={ (e) => displayDealershipInformation(record) } />

                                <EditButton
                                    size="small"
                                    recordItemId={ record.id }
                                    hideText
                                    onClick={ () => editModalShow(record.id) }
                                />

                                <DeleteButton 
                                    hideText
                                    recordItemId={ record.id }
                                />

                            </Space>

                        )}
                    />
                   
				</Table>

			</List>

			<Modal 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                okType="success"
                onCancel={ hideDealershipInformation }
                onOk={ hideDealershipInformation }
                open={ isDealershipModalOpen }
                title={ currentlyDisplayingDealership ? <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}> { currentlyDisplayingDealership.dealership }</p> : 'NO DEALER IN AREA' }
                width={ 700 }
            >

                {
                    currentlyDisplayingDealership &&
                    <>
                        <Row gutter={[12, 23]}>
                            
                            {
                                currentlyDisplayingDealership.gm_owner &&
                                <Col span={ 12 }>
                                    <Title level={ 5 }>GM / Owner</Title>
                                    <Text>{ currentlyDisplayingDealership.gm_owner }</Text>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.contact_email &&
                                <Col span={ 12 }>
                                    <Title level={5}>Contact email</Title>
                                    <Text>{ currentlyDisplayingDealership.contact_email }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.dealer_type &&
                                <Col span={ 12 }>
                                    <Title level={5}>Dealer type</Title>
                                    <Text>{ currentlyDisplayingDealership.dealer_type }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.phone_no &&
                                <Col span={ 12 }>
                                    <Title level={5}>Phone no.</Title>
                                    <Text>{ currentlyDisplayingDealership.phone_no }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.territory &&
                                <Col span={ 12 }>
                                    <Title level={5}>Territory</Title>
                                    <Text>{ currentlyDisplayingDealership.territory }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.source_tab_name &&
                                <Col span={ 12 }>
                                    <Title level={5}>Source tab name    (from xlsx file)</Title>
                                    <Text>{ currentlyDisplayingDealership.source_tab_name }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                            {
                                currentlyDisplayingDealership.is_active !== undefined &&
                                <Col span={ 12 }>
                                    <Title level={5}>Is active?</Title>
                                    <Text>{ currentlyDisplayingDealership.is_active ? 'YES' : 'NO' }</Text><br/>&nbsp;<br/>
                                </Col>
                            }

                        </Row>

                        

                       

                        {
                            currentlyDisplayingDealership.dealerships_metadata.length > 0 &&
                            <>
                            <br/>
                            <Title level={ 2 }>Aditional details</Title>

                            <Divider orientation="left" />
                            <Row>

                            {
                                currentlyDisplayingDealership.dealerships_metadata.map((dealerMetadata) => 
                                        <Col key={ `metakey_${ dealerMetadata.meta_key }` } span={ 12 }>
                                            <Title level={ 5 }>{ dealershipsMetasCatalog?.data?.find(e => e.id === dealerMetadata.meta_key)?.label.replace(/^./, str => str.toUpperCase()) }</Title>
                                            <Text>{ dealerMetadata.meta_value }</Text><br/>&nbsp;<br/>
                                        </Col> 
                                ) 
                            }
                            </Row>
                            </>
                            
                        }

                        


                    </>
                }

            </Modal>



            <Modal { ...editModalProps }>

                <Form { ...editFormProps } layout="vertical" onFinish={ handleOnEditFinish }>

                    <Form.Item 
                        label="Dealership"
                        name="dealership"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="GM / Owner"
                        name="gm_owner"
                        
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item 
                        label="Dealer type"
                        name="dealer_type"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Phone no."
                        name="phone_no"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="E-mail"
                        name="contact_email"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Territory"
                        name="territory"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Source tab name"
                        name="source_tab_name"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Is active"
                        name="is_active"
                        rules={ [ { required: false } ] }
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>


                    {
                            dealershipsMetasCatalog?.data?.length > 0 &&
                            <>
                            <br/>
                            <Title level={ 2 }>Aditional details</Title>

                            <Divider orientation="left" />

                            {
                                dealershipsMetasCatalog?.data?.map((metaKey, metaIndex) => 
                                    <Form.Item 
                                        initialValue={ editFormProps?.initialValues?.dealerships_metadata.find(e => e.meta_key === metaKey.id )?.meta_value }
                                        key={ `dealerMetaData_${ metaKey.id }` }
                                        label={ metaKey.label.replace(/^./, str => str.toUpperCase()) }
                                        name={ `dealerships_metadata_${ metaKey.id }` }
                                        rules={ [ { required: false } ] }
                                    >
                                        <Input />
                                    </Form.Item>
                                       
                                ) 
                            }
                            </>
                            
                        }


                </Form>

            </Modal>


            <Modal { ...createModalProps }>

                <Form { ...createFormProps } layout="vertical">

                    <Form.Item 
                        label="Dealership"
                        name="dealership"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="GM / Owner"
                        name="gm_owner"
                        
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item 
                        label="Dealer type"
                        name="dealer_type"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Phone no."
                        name="phone_no"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="E-mail"
                        name="contact_email"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Territory"
                        name="territory"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Source tab name"
                        name="source_tab_name"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Is active"
                        name="is_active"
                        rules={ [ { required: false } ] }
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <br/>
                    <Title level={ 2 }>Aditional details</Title>

                    <Divider orientation="left" />


                    {
                        dealershipsMetasCatalog?.data?.map(metaKey => 

                            <Form.Item
                                key={ metaKey.label }
                                label={ metaKey.label.replace(/^./, str => str.toUpperCase()) }
                                name={ metaKey.meta_key }
                            >
                                <Input />
                            </Form.Item>

                        )
                    }

                </Form>

            </Modal>

		</>

	);

}