import React, { useState } from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { useNotification } from "@refinedev/core";
import { Alert, Button, Col, Flex, message, Radio, Row, Select, Spin, Typography, Upload } from 'antd';
import * as XLSX from 'xlsx';

import { API_URL } from '../../config/settings';

const { Dragger } = Upload;

const PREV_OPERATIONS = {
    FILE_SELECTION: 0,
    SELECT_WORKSHEET: 1,
    SET_SHEET_HEADERS: 2
};

const mimeAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/xls, text/csv, application/vnd.oasis.opendocument.spreadsheet, application/vnd.apple.numbers";

const { Text, Title } = Typography;


export const Uploader = (_props) => {

    const { open } = useNotification();

    const [columnsMap, setColumnsMap] = useState({ 
        city: 'CITY',
        area: 'AREA (Code)',
        county: 'COUNTY',
        dealership: 'DEALERSHIP',
        state: 'STATE',
        zip_code: 'ZIPS',
        salesRep: 'SALES REP',
        salesRepText: 'SALES REP TEXT', 
        various: 'VARIOUS',
        code: 'CODE',
        specialInstructions: 'SPECIAL INSTRUCTIONS'
    });

    const [fileColumns, setFileColumns] = useState([ ]);
    const [fileOperation, setFileOperation] = useState(PREV_OPERATIONS.FILE_SELECTION);
    const [fileList, setFileList] = useState([ ]);
    const [uploading, setUploading] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [ workBook, setWorkBook ] = useState({ });
    const [worksheet, setWorksheet] = useState(null);
    const [worksheetNames, setWorksheetNames] = useState(null);

    const handleUpload = () => {
        const formData = new FormData();
        
        formData.append('file', fileList[0]);
        if(worksheet){
            formData.append('worksheet', worksheet);
        }

        formData.append('columnsMap', JSON.stringify(columnsMap));

        setUploading(true);

        fetch(`${ API_URL }/zips/update`, {
            method: 'POST',
            body: formData,
        })
        .then((res) => res.json())
        .then((res) => {
            open?.({
                type: "success",
                message: "Success",
                description: res.message,
                key: "unique-id",
            });
            setFileList([]);

            message.success('Bulk update ran successfully.');
        })
        .catch(() => {
            message.error('Bulk update failed.');
        })
        .finally(() => {
            setFileOperation(PREV_OPERATIONS.FILE_SELECTION);
            setUploading(false);
        });
    };


    const draggerProps = {
        accept: mimeAccept,
        beforeUpload: (file) => {
            if(mimeAccept.indexOf(file.type) > -1){
                setWaiting(true);
                setFileList([file]);
                openFile(file);
            }
            return false;
        },
        fileList,
        onDrop(e) {
            
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        }
    };


    const openFile = async (_file) => {

        setWaiting(true);

        const data = await _file.arrayBuffer();

        const workbook = XLSX.read(data);

        setWorkBook(workbook);

        // const wsnames = workbook.SheetNames;

        // if(wsnames.length > 1){

        //     setWorksheetNames(wsnames);
        //     setFileOperation(PREV_OPERATIONS.SELECT_WORKSHEET);

        // } else {

        //     setWorksheet(wsnames[0]);
        //     setFileOperation(PREV_OPERATIONS.SET_SHEET_HEADERS);

        // }

        setWaiting(false);

    }



    const renderColumnSelection = () => {

        const _options = fileColumns.map(col => { return { label: col, value: col } });

        let readyToUpload = true;

        let requiredFields = _props.requiredFields.filter(e => e.required);

        requiredFields.forEach(field => {
            if(!columnsMap[field.name]){
                readyToUpload = false;
            }
        });

        return <>

            <Title level={ 4 }>Lets set up the worksheet columns:</Title>
            <Row gutter={ [ 32, 16 ] } style={{ margin: 13 }}>

                <Col span={ 6 }><Title level={ 5 }>Required column</Title></Col>
                <Col span={ 8 }><Title level={ 5 }>Select corresponding column</Title></Col>

            </Row>

            {
                _props.requiredFields.map(e =>
                        <Row gutter={ [ 48, 16 ] } key={ `fileColumn_${ e.name }` } style={{ margin: 13 }} >
                            <Col span={ 6 } style={{ textAlign: 'right' }}><Text>{ e.name }</Text></Col>
                            <Col span={ 8 }>
                                <Select
                                    onChange={ v => updateColumnsMap(e.name, v) }
                                    options={ _options }
                                    status={ e.required && !columnsMap[e.name] ? `error` : null }
                                    style={{ width: '97%' }}
                                />
                            </Col>
                        </Row>
                    )
            }
            <Button
                type="primary"
                onClick={ handleUpload }
                disabled={ !readyToUpload }
                loading={ uploading }
                size="large"
                style={{
                    marginTop: 16,
                }}
            >
                Upload
            </Button>

            

        </>

    }


    const renderFileSelection = () => 
        <>
          <Dragger { ...draggerProps }>
              <p className="ant-upload-drag-icon">
                  <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                  Support for single upload. <br/>Accepts XLSX, XLS, ODS, Numbers and CSV file format.
              </p>
          </Dragger>

          <Button
              type="primary"
              onClick={ handleUpload }
              disabled={ fileList.length === 0 && !uploading }
              loading={ uploading }
              style={{
                  marginTop: 16,
              }}
          >
              { uploading ? 'Uploading' : 'Start Upload' }
          </Button>

        </>
    ;


    const renderUploaderInterface = () => {

        switch(fileOperation){

            case PREV_OPERATIONS.FILE_SELECTION:
                return renderFileSelection();

            break;


            case PREV_OPERATIONS.SELECT_WORKSHEET:
                return renderWorksheetSelection();

            break;


            case PREV_OPERATIONS.SET_SHEET_HEADERS:

                return renderColumnSelection();

            break;

        }

    }


    const renderWorksheetSelection = () => 

        <>

            <Title level={ 5 }>Found multiple worksheets in your book. Choose which one contains the data to work with:</Title> 

            <Radio.Group value={ worksheet } onChange={ (e) => setWorksheet(e.target.value) } >
                <Flex vertical="vertical">
                    { worksheetNames.map((e, i) => <div key={`Option_${ i }`} style={{ padding: 7 }}><Radio  value={ e }>{ e }</Radio> </div> ) }
                </Flex>
            </Radio.Group>


            <Button
                type="primary"
                onClick={ setupColumns }
                disabled={ !worksheet }
                loading={ uploading }
                size="large"
                style={{
                    marginTop: 16,
                }}
            >
                Next
            </Button>

        </>
    ;


    const setupColumns = () => {

        const ws = workBook.Sheets[worksheet];

        let headers = []
        const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1;

        for (let i = 0; i < columnCount; ++i) {
            if(ws[`${XLSX.utils.encode_col(i)}1`])
            headers.push(ws[`${XLSX.utils.encode_col(i)}1`].v);
        }

        setFileColumns(headers);

        setFileOperation(PREV_OPERATIONS.SET_SHEET_HEADERS);

    }


    const updateColumnsMap = (key, colName) => {

        setColumnsMap({ ...columnsMap, [key]: colName });

    }


    return (
        <div style={{ marginTop: 19 }} >
            <Spin spinning={ waiting }>
                { renderUploaderInterface() }
            </Spin>
        </div>
    );

};