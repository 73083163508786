import React, { useState } from 'react';

import { Menu, Upload } from 'antd';
import { Uploader } from '../uploader';


export const Updater = () => {

	const mainMenuItems = [

		// {
		// 	key: 'salesReps',
		// 	label: 'Sales reps data',
		// 	target: 'Uploader'
		// },
		{
			key: 'zipcodes',
			label: 'Zip dealerships',
			target: 'Uploader'
		}
		// {
		// 	label: 'Dealerships data',
		// 	key: 'dealerships'
		// }

	];


	const requiredProps = {
		salesReps: [ { name: 'zip_code', required: true }, { name: 'salesRep', required: true }, { name: 'code', required: false }, { name: 'area', required: false }, { name: 'specialInstructions', required: false } ],

		zipCodes: [ 
			{ name: 'city', required: true }, 
			{ name: 'area', required: false }, 
			{ name: 'county', required: true }, 
			{ name: 'dealership', required: false }, 
			{ name: 'state', required: true }, 
			{ name: 'zip_code', required: true }, 
			{ name: 'salesRep', required: false }, 
			{ name: 'salesRepText', required: false }, 
			{ name: 'various', required: false }, 
			{ name: 'code', required: false }, 
			{ name: 'specialInstructions', required: false } 
		]
		
		
		// dealerships: [ { name: 'zip_code', required: true }, { name: 'dealership', required: true } ]
	}


	const [currentlyDisplayingItem, setCurrentlyDisplayingItem] = useState('zipCodes');

	const onClick = (e) => {
		setCurrentlyDisplayingItem(e.key);
	};

	return (
		<div style={{ maxWidth: 900, height: '50vh' }} >
			<Menu onClick={ onClick } selectedKeys={ [ currentlyDisplayingItem ] } mode="horizontal" items={ mainMenuItems }  />
			<Uploader requiredFields={ requiredProps[ currentlyDisplayingItem ] } />
		</div>
	);

}