import { SearchOutlined } from '@ant-design/icons';
import { DeleteButton, EditButton, List, SaveButton, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { useGo } from "@refinedev/core";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Table, Typography } from "antd";
import React, { useState } from 'react';

const{ Title, Text } = Typography;

export const ZipsDealershipsList = () => {

    const [ currentState, setCurrentState ] = useState(0);
    const [ currentCounty, setCurrentCounty ] = useState(0);
    const [ currentCity, setCurrentCity ] = useState(0);
    const [ currentRelationship, setCurrentRelationship ] = useState({});

    const go = useGo();

	const { searchFormProps, tableProps, tableQueryResult } = useTable({
        onSearch: (values) => {
            return [
                {
                    field: "state",
                    operator: "equals",
                    value: values.state,
                },
                {
                    field: "ziporcounty",
                    operator: "contains",
                    value: values.ziporcounty,
                },
            ];
        },
		resource: 'zips'
	});


    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm({
        action: "edit",
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            tableQueryResult.refetch();
        },
        resource: "zipsDealerships"
    });


    const { selectProps } = useSelect({
        optionLabel: "dealership",
        optionValue: "id",
        resource: "dealerships"
    });


    if(selectProps?.options && selectProps?.options[0]?.value !== 0)
        selectProps.options.unshift({ label: 'NO DEALERSHIP', value: 0 });


    const { selectProps: salesRepSelectProps } = useSelect({
        optionLabel: "salesRep",
        optionValue: "id",
        resource: "salesReps"
    });

    if(salesRepSelectProps?.options && salesRepSelectProps?.options[0]?.value !== 0)
        salesRepSelectProps.options.unshift({ label: 'NO SALES REP', value: 0 });
    


	return (

		<>

			<List 
                title="Zip codes management"
                headerButtons={({ defaultButtons }) => (
                    <>
                        { defaultButtons }

                        <Button type="primary" onClick={ () => {
                            go({
                                to: '/bulkUpdate',
                                type: 'push'
                            })
                        } }>Bulk update</Button>

                        <Button type="primary" onClick={ () => {
                            go({
                                to: '/uploadsLog',
                                type: 'push'
                            })
                        } }>Uploads log</Button>
                    </>
                )}
            >
                <Form {...searchFormProps} layout="inline">

                    <Form.Item name="ziporcounty">
                        <Input placeholder="Enter zip or county" allowClear onChange={ e => { if(e.target.value === '') searchFormProps.form?.submit()  } } />
                    </Form.Item>

                    <Form.Item name="state">
                         <Select
                            placeholder="State"
                            style={{ width: 200 }}
                            { ...selectProps }
                        />
                    </Form.Item>


                    <Button icon={<SearchOutlined />} onClick={searchFormProps.form?.submit}>Search</Button>
                    <br/>&nbsp;<br/>
                </Form>
				<Table { ...tableProps } rowKey="id">

                    <Table.Column dataIndex="zip_code" title="Zip code" />
                    <Table.Column dataIndex="cities_c.city" title="City" render={ (t, r) => r.cities_c ? r.cities_c.city : '' } />
                    <Table.Column dataIndex="cities_c.city" title="County" render={ (t, r) => r.cities_c?.counties_c ? r.cities_c.counties_c.county : '' } />
                    <Table.Column dataIndex="cities_c.city" title="State" render={ (t, r) => r.cities_c?.counties_c?.states_c ? r.cities_c.counties_c.states_c.state : '' } />
                    
                    
                    <Table.Column dataIndex="dealerships" title="Dealership" render={ (t, r) => r.dealerships && r.dealerships.length ? r.dealerships[0].dealership : 'NO DEALER IN AREA' } />
                    <Table.Column dataIndex="salesReps" title="Sales Rep" render={ (t, r) => r.salesReps && r.salesReps.length ? r.salesReps[0].salesRep : 'NO SALES REP SET' } />

                    <Table.Column
                        title="Actions"
                        dataIndex="actions"

                        render={ (_text, record) => (

                            <Space>

                                <EditButton
                                    onClick={ e => { setCurrentRelationship(record); return editModalShow(record.id); } }
                                    size="medium"
                                    recordItemId={ record.id }
                                >
                                    Update dealership / Sales rep
                                </EditButton>
                            </Space>

                        )}
                    />
                   
				</Table>

			</List>


            <Modal { ...editModalProps }>

                <Form { ...editFormProps } layout="vertical">

                    <Row style={{ marginTop: 17, marginBottom: 13 }}>

                        <Col span={ 8 }>
                            <Text>State: </Text>
                            <Title level={ 5 } style={{ margin: 0 }}>{ currentRelationship?.cities_c?.counties_c.states_c.state }</Title>
                        </Col>

                        <Col span={ 8 }>

                            <Text>County: </Text>
                            <Title level={ 5 } style={{ margin: 0 }}>{ currentRelationship?.cities_c?.counties_c.county }</Title>

                        </Col>

                    </Row>
                    <Row style={{ marginTop: 17, marginBottom: 13 }} >

                        <Col span={ 8 }>

                            <Text>City: </Text>
                            <Title level={ 5 } style={{ margin: 0 }}>{ currentRelationship?.cities_c?.city }</Title>

                        </Col>
                        <Col span={ 8 }>

                            <Text>Zip code: </Text>
                            <Title level={ 4 } style={{ margin: 0 }}>{ currentRelationship?.zip_code }</Title>

                        </Col>
                   

                    </Row>

                    <Form.Item 
                        label="Zip code"
                        name="zip_code"
                        hidden
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Dealership"
                        name="dealership"
                        rules={[
                            
                        ]}
                    >
                        <Select {...selectProps } />
                    </Form.Item>

                    <Form.Item
                        label="Sales rep"
                        name="salesRep"
                        rules={[
                            
                        ]}
                    >
                        <Select {...salesRepSelectProps } />
                    </Form.Item>

                </Form>

            </Modal>

		</>

	);

}