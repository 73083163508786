import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { DeleteButton, EditButton, List, SaveButton, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import React from 'react';

const{ Title, Text } = Typography;

export const DealershipsMetaCList = () => {

	const { searchFormProps, tableProps } = useTable({
        // onSearch: (values) => {
        //     return [
        //         {
        //             field: "state",
        //             operator: "contains",
        //             value: values.state,
        //         },
        //     ];
        // },
		resource: 'dealershipsMetaC'
	});


	const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm({
        action: "create",
        resource: "dealershipsMetaC"
    });


    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm({
        action: "edit",
        resource: "dealershipsMetaC",
        warnWhenUnsavedChanges: true,
    });


    return (

		<>

			<List 
                createButtonProps={{
                    onClick: () => {
                        createModalShow();
                    }
                }}
                title="Dealerships extra-fields management"
            >

                {/*<Form {...searchFormProps} layout="inline">

                    <Form.Item name="label">
                        <Input placeholder="State" allowClear onChange={ e => { if(e.target.value === '') searchFormProps.form?.submit()  } } />
                    </Form.Item>

                   

                    <Button icon={<SearchOutlined />} onClick={searchFormProps.form?.submit}>Search</Button>
                    <br/>&nbsp;<br/>
                </Form>*/}
				<Table { ...tableProps } 
                    rowKey="id">

					<Table.Column dataIndex="label" title="Label" />
                    <Table.Column dataIndex="meta_key" title="Key name" />
                    <Table.Column dataIndex="status" title="Is active" render={ (_, record) => record.status ? 'YES' : 'NO' } />
                    

                    <Table.Column
                        title="Actions"
                        dataIndex="actions"

                        render={ (_text, record) => (

                            <Space>

                                <EditButton
                                    size="small"
                                    recordItemId={ record.id }
                                    hideText
                                    onClick={ () => editModalShow(record.id) }
                                />

                                <DeleteButton 
                                    hideText
                                    recordItemId={ record.id }
                                />

                            </Space>

                        )}
                    />
                   
				</Table>

			</List>


			 <Modal { ...editModalProps }>

                <Form { ...editFormProps } layout="vertical">

                    <Form.Item 
                        label="Label (How it's displayed to user)"
                        name="label"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Key (How it's displayed to a computer [No spaces, no special characters])"
                        name="meta_key"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Is active"
                        name="status"
                        rules={ [ { required: false } ] }
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                </Form>

            </Modal>


            <Modal { ...createModalProps }>

                <Form { ...createFormProps } layout="vertical">

                    <Form.Item 
                        label="Label (How it's displayed to user)"
                        name="label"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Key (How it's displayed to a computer [No spaces, no special characters])"
                        name="meta_key"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Is active"
                        name="status"
                        rules={ [ { required: false } ] }
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                </Form>

            </Modal>

		</>

	);

}